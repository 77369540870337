<template>
  <!--begin::Dashboard-->
  <div id="profile" v-if="isProfileLoaded && isUserLoaded">
    <!--begin::Row-->
    <b-row class="mt-10">
      <b-col xl="6" v-if="hasModule(['awareness']) || hasModule(['abs'])">
        <chart
          v-if="isProfileLoaded && hasModule(['awareness'])"
          :title="$t('generic.modules.cybersecurity.title')"
          :subtitle="$t('generic.modules.cybersecurity.subtitle')"
          :series="[
            {
              name: $t('generic.modules.cybersecurity.title'),
              data: cybersecurity.series
            }
          ]"
          :categories="cybersecurity.labels"
          :dates="cybersecurity.dates"
          :discrete="cybersecurity.discrete"
          :height="visibleModules.length > 4 ? '300' : '200'"
        />
        <pwned v-if="hasModule(['abs'])" />
      </b-col>
      <b-col xl="6">
        <b-row class="mt-5">
          <b-col
            class="mb-15"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            v-for="mod in visibleModules"
            :key="mod.icon"
          >
            <module :card="mod" style="min-height: 100%;" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--end::Row-->
  </div>
  <!--end::Dashboard-->
</template>

<script>
import Chart from "@/components/profile/Chart.component";
import Module from "@/components/profile/Module.component";
import Pwned from "@/components/profile/Pwned.component";

import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "Profile",
  components: {
    Chart,
    Module,
    Pwned
  },
  computed: {
    ...mapGetters("User", ["isUserLoaded"]),
    ...mapGetters("Profile", ["isProfileLoaded", "cybersecurity", "modules"]),
    ...mapGetters("Auth", ["hasModule"]),
    visibleModules() {
      return _.filter(
        this.modules,
        mod => mod.visible === undefined || mod.visible !== false
      );
    },
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("User", ["fullname", "archetypeSvg", "archetypeId"])
  },
  methods: {
    ...mapActions("Profile", ["loadProfile"])
  },
  mounted() {
    if (this.isConstantsActive && !this.isProfileLoaded) {
      this.loadProfile();
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadProfile();
      }
    },
    isProfileLoaded(value) {
      if (this.isConstantsActive && value === false) {
        this.loadProfile();
      }
    }
  }
};
</script>
