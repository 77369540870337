<template>
  <!--begin::Iconbox-->
  <b-card
    :class="`card stacked--up card-stretch card-custom cardround module-cards`"
    no-body
    v-if="isProfileLoaded"
  >
    <div class="card-body pb-0">
      <b-dropdown
        class="dropdown dropdown-inline ml-2 corner-dropdown"
        toggle-class="btn btn-sm btn-icon"
        variant="clean"
        menu-class="dropdown-menu p-0 m-0"
        size="sm"
        offset="10"
        right
        :no-caret="true"
      >
        <template slot="button-content">
          <i class="ki ki-bold-more-ver"></i>
        </template>
        <template slot="default">
          <ul class="navi navi-hover">
            <li class="navi-item">
              <a class="navi-link" @click="goToCardDetail">
                <span class="navi-text">{{
                  $t("profile.modules.actions.details")
                }}</span>
              </a>
            </li>
            <li v-if="hasAssets(card.id)" class="navi-item">
              <router-link
                :to="{ name: 'Assets', params: { family: card.id } }"
                class="navi-link"
              >
                <span class="navi-text">{{
                  $t("profile.modules.actions.docs")
                }}</span>
              </router-link>
            </li>
            <li class="navi-item" v-if="pendingInteraction">
              <a class="navi-link" @click="openChat(card.id)">
                <span class="navi-text">{{
                  $t("profile.modules.actions.next")
                }}</span>
              </a>
            </li>
          </ul>
        </template>
      </b-dropdown>
      <div :class="`floating-top top-icon ${card.color}`">
        <i :class="`fa tcicon ${card.icon}`" v-if="card.icon"></i>
        <span :class="`svg-icon svg-icon-4x svg-icon-white`" v-if="card.image">
          <simple-svg :src="require(`@/assets/images/icons/${card.image}`)" />
        </span>
      </div>
      <div class="d-flex align-items-center p-2 pt-6">
        <div class="d-flex flex-column text-center" style="width: 100%;">
          <span>
            <router-link
              v-if="titleLink"
              class="text-dark text-hover-primary font-weight-bolder font-size-h3 mb-3"
              :to="{ name: titleLink }"
              v-html="card.title"
            ></router-link>
            <span
              v-else-if="link && card.surveyStatus"
              style="cursor:pointer;"
              @click="goToCardDetail"
              class="text-dark text-hover-primary font-weight-bolder font-size-h3 mb-3"
              v-html="card.title"
            ></span>
            <router-link
              v-else-if="link && !card.surveyStatus"
              class="text-dark text-hover-primary font-weight-bolder font-size-h3 mb-3"
              :to="card.to"
              v-html="card.title"
            ></router-link>
            <span
              v-else
              class="text-dark font-weight-bold font-size-h4 mb-3"
              v-html="card.title"
            ></span>
          </span>
          <div class="text-dark-65 pt-4">
            {{ card.shortDesc }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-footer d-flex flex-wrap justify-content-center border-0 my-4 px-0"
    >
      <b-button
        pill
        variant="primary"
        size="sm"
        class="px-auto font-weight-bolder mr-2 mb-2"
        @click="goToCardDetail"
      >
        <div class="d-flex align-items-center">
          <i class="fa fa-eye" />
          <span style=" white-space: nowrap">
            {{ $t("tables.seeDetails") }}
          </span>
        </div>
      </b-button>

      <b-button
        v-if="hasAssets(card.id)"
        pill
        variant="secondary"
        size="sm"
        class="px-auto font-weight-bolder mr-2 mb-2"
        @click="$router.push({ name: 'Assets', params: { family: card.id } })"
      >
        <div class="d-flex align-items-center">
          <span style=" white-space: nowrap">
            {{ $t("tables.pills") }}
          </span>
        </div>
      </b-button>

      <b-button
        v-if="pendingInteraction"
        pill
        variant="dark"
        size="sm"
        class="px-auto font-weight-bolder mr-2 mb-2"
        @click="openChat(card.id)"
      >
        <div class="d-flex align-items-center">
          <i class="fa fa-chevron-right" />
          <span style=" white-space: nowrap">
            {{ $t("tables.session") }}
          </span>
        </div>
      </b-button>
    </div>
  </b-card>
  <!--end::Iconbox-->
</template>

<script>
import _ from "lodash";
import { SimpleSVG } from "vue-simple-svg";

import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  name: "Module",
  components: {
    "simple-svg": SimpleSVG
  },
  data() {
    return {
      isHovering: false
    };
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    link: {
      type: Boolean,
      required: false,
      default: true
    },
    titleLink: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters("Profile", [
      "getNextIterationByModule",
      "isProfileLoaded",
      "assets",
      "interactions"
    ]),
    assetsAvailables() {
      return {
        files: _.filter(
          this.assets,
          asset =>
            asset.family === this.card.id && !_.includes(asset.type, "_iframe")
        ),
        videos: _.filter(
          this.assets,
          asset =>
            asset.family === this.card.id && _.includes(asset.type, "_iframe")
        )
      };
    },
    pendingInteraction() {
      var last = this.getNextIterationByModule(this.card.id);
      if (last) {
        return (
          moment().isAfter(last.userStartDate) &&
          (_.toLower(last.surveyStatus) == "available" ||
            _.toLower(last.surveyStatus) == "progress")
        );
      }
      return false;
    }
  },
  methods: {
    ...mapActions("Chatbot", ["activate"]),
    hasAssets(id) {
      const assets = _.filter(this.assets, { family: id });
      return assets.length > 0;
    },
    moment(value) {
      return moment(value);
    },
    getRandomNumberBetween(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    openChat(id) {
      let survey = this.getNextIterationByModule(id);
      this.activate(survey.surveyEntityId);
    },
    goToCardDetail() {
      if (this.card.to) {
        this.$router.push(this.card.to);
      } else {
        switch (_.toLower(this.card.surveyStatus)) {
          case "finish":
            this.$router.push({
              name: "TrainingDetail",
              params: { id: this.card.surveyEntityId }
            });
            break;
          case "progress":
          case "available":
            this.activate(this.card.surveyEntityId);
            this.$root.$emit("bv::show::modal", "chatbot");
            break;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.module-cards {
  margin-top: -20px;
}
.topcard {
  height: auto;
  padding: 2rem;
  .tcicon {
    font-size: 4rem;
  }
}

.corner-dropdown {
  position: absolute;
  right: 10px;
  top: 15px;
}
.navi-link {
  cursor: pointer;
}
$animation-timing: 0.3s;
$animation-ease: ease-in-out;
$animation-ease-bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
[class*="stacked--"] {
  position: relative;
  transition: transform $animation-timing $animation-ease;
  will-change: transform;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform $animation-timing $animation-ease;
    will-change: transform;
  }

  &:before {
    z-index: -1;
  }

  &:after {
    z-index: -2;
  }
}
.stacked--up {
  &:before,
  &:after {
    transform-origin: center bottom;
    border-radius: 30px;
  }
  transform: translate(0, -5px);
  &:before {
    transform: translate(0, 5px) scale(1);
    border-radius: 30px;
    background-color: transparent;
    box-shadow: 0 -5px 10px 5px rgb(0 0 0 / 6%) !important;
  }
  &:after {
    transform: translate(0, 10px) scale(1);
    z-index: -1;
    border-radius: 30px;
    background-color: transparent;
    box-shadow: 0 -3px 3px 0px rgb(0 0 0 / 6%) !important;
  }
}
.bg-light-tc1 {
  background-color: #cabbab20;
}
.bg-light-tc2 {
  background-color: #a1a3d320;
}
.bg-light-tc3 {
  background-color: #ce93b820;
}
.bg-light-tc4 {
  background-color: #afd3ca20;
}
.bg-light-tc5 {
  background-color: #f3c33c20;
}
.bg-light-tc6 {
  background-color: #7fc6ef20;
}
</style>
