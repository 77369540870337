<template>
  <div
    :class="`card card-custom card-stretch card-stretch-half gutter-b`"
    v-if="pwnedStatus !== false && pwned && Object.keys(pwned) > 0"
  >
    <div class="card-body d-flex">
      <div class="d-flex align-items-center p-2">
        <div class="mr-10">
          <span :class="`svg-icon svg-icon-${pwnedStatus} svg-icon-6x`">
            <simple-svg
              v-if="pwnedStatus === 'warning'"
              :src="require('@/assets/images/icons/warning-circle.svg')"
            />
            <simple-svg
              v-else-if="pwnedStatus === 'success'"
              :src="require('@/assets/images/icons/done-circle.svg')"
            />
            <simple-svg
              v-else-if="pwnedStatus === 'danger'"
              :src="require('@/assets/images/icons/warning-triangle.svg')"
            />
            <simple-svg
              v-else
              :src="require('@/assets/images/icons/mail-locked.svg')"
            />
          </span>
        </div>
        <div class="d-flex flex-column">
          <p class="text-dark mb-5 text-left font-size-h3 font-weight-bolder">
            {{ $t(`dashboard.pwned.${pwnedStatus}.title`) }}
          </p>
          <div class="text-dark-75 text-left pb-8">
            {{
              $t(`dashboard.pwned.${pwnedStatus}.content.description`, {
                date: date
              })
            }}
          </div>
          <b-button
            class="font-weight-bolder text-uppercase py-4 mt-2"
            tag="a"
            :variant="pwnedStatus"
            :to="{ name: 'Breaches' }"
            pill
            v-if="pwnedStatus !== 'success'"
          >
            {{ $t(`dashboard.pwned.${pwnedStatus}.content.ending`) }}
          </b-button>
          <b-button
            class="font-weight-bolder text-uppercase py-4 mt-2"
            tag="a"
            :variant="pwnedStatus"
            pill
            @click="callBot({ bot: absBot })"
            v-else
          >
            {{ $t(`dashboard.pwned.${pwnedStatus}.content.ending`) }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { SimpleSVG } from "vue-simple-svg";
import { getHelpersBots } from "@/api/user/traits.api";

export default {
  name: "Pwned",
  components: {
    "simple-svg": SimpleSVG
  },
  computed: {
    ...mapGetters("Chatbot", ["bots"]),
    ...mapGetters("Profile", ["pwnedStatus", "isProfileLoaded", "pwned"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    date() {
      return this.pwned.answer
        ? moment(this.pwned.answer.maxdate).format("LL")
        : "";
    },
    absBot() {
      return _.find(this.bots, {
        surveyTypeId: parseInt(this.getConstant("SURVEY_KYMATIO_ABSBOT"))
      });
    }
  },
  methods: {
    ...mapActions("Chatbot", ["activate", "addNewBot", "callBot"]),
    ...mapActions("Profile", ["reloadBreaches"]),
    getAbsBot() {
      let surveyAbsTypeId = parseInt(
        this.getConstant("SURVEY_KYMATIO_BREACH_CORPORATE")
      );
      getHelpersBots([surveyAbsTypeId]).then(resp => {
        this.addNewBot(_.first(resp.data.records));
      });
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.reloadBreaches();
        this.getAbsBot();
      }
    }
  },
  mounted() {
    this.reloadBreaches();

    if (this.isConstantsActive) {
      if (!this.absBot) {
        this.getAbsBot();
      }
    }
  }
};
</script>
